import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import s from "./team.module.css";
import { getBaseUrl2 } from "../../utils";
import { notificationHandler } from "../../utils/Notification";
import {
  getLeadData_api,
  getLeadDataForAdmin_api,
  getSingleUser_api,
  update_imageUrlStatus_api,
  update_imageUrlStatusForManager_api,
} from "../api/admin";
import { BiArrowBack } from "react-icons/bi";
import ImagePopUp from "./ImagePopUp";
import Cookies from "js-cookie";

const ViewPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const page = location?.state?.pagetype;
  const [imagePopUp, setimagePopUp] = useState(false);
  const [imageData, setimageData] = useState("");
  const [singleLead, setsingleLead] = useState({});

  let urlUpdation = singleLead?.urlImageUploads;
  let role = Cookies.get("role");

  useEffect(() => {
    if (page == "Lead") {
      if (role == "Admin") {
        fetchSingleLeadForAdmin();
      }
      if (role == "Manager") {
        fetchSingleLeadData();
      }
    }
    if (page == "User") {
      fetchSingleUserFunc();
    }
  }, []);

  const fetchSingleUserFunc = async () => {
    try {
      let res = await getSingleUser_api(location.state.data._id);
      if (res.data.status) {
        setsingleLead(res?.data?.data.data);
      } else {
        console.log("false Status!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSingleLeadForAdmin = async () => {
    try {
      let res = await getLeadDataForAdmin_api(location.state.data._id);
      if (res.data.status) {
        setsingleLead(res?.data?.data?.Leads);
      } else {
        console.log("false Status!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSingleLeadData = async () => {
    try {
      let res = await getLeadData_api(location.state.data._id);
      if (res.data.status) {
        setsingleLead(res?.data?.data?.Leads);
      } else {
        console.log("false Status!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //     updateUserStatus();
  // }, [])

  const updateUserStatus = async (e, row) => {
    let temp = {
      status: e.target.value,
      leadId: row?.leadId,
    };
    if (role == "Admin") {
      try {
        let res = await update_imageUrlStatus_api(temp, row._id);
        if (res.data.status) {
          // navigate("/leads")
          // updateUserStatus();
          if (role == "Admin") {
            fetchSingleLeadForAdmin();
          }
          if (role == "Manager") {
            fetchSingleLeadData();
          }
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "danger", msg: res.data.message });
        }
      } catch (error) {
        console.log("Status False");
        notificationHandler({ type: "danger", msg: error.message });
      }
    }
    if (role == "Manager") {
      try {
        let res = await update_imageUrlStatusForManager_api(temp, row._id);
        if (res.data.status) {
          // navigate("/leads")
          // updateUserStatus();
          if (role == "Admin") {
            fetchSingleLeadForAdmin();
          }
          if (role == "Manager") {
            fetchSingleLeadData();
          }
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "danger", msg: res.data.message });
        }
      } catch (error) {
        console.log("Status False");
        notificationHandler({ type: "danger", msg: error.message });
      }
    }
  };

  const handleImage = (data) => {
    console.log("function is running in the imgage pop up function");
    setimageData(data);
    setimagePopUp(true);
  };

  return (
    <div>
      <div className="back_btn">
        <div className={s["title"]} onClick={() => navigate(-1)}>
          <BiArrowBack />
          Back
        </div>
      </div>
      <div className={s["user_details"]}>
        <div className="container">
          <Table border={Table}>
            <TableHead aria-colspan={2}>
              <h3
                style={{
                  display: "flex",
                  width: "170%",
                  justifyContent: "center",
                }}
              >
                {page == "Lead" ? "Lead Detail" : "User Details"}
              </h3>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>{singleLead?.fullName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Email</b>
                </TableCell>
                <TableCell>{singleLead?.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Phone Number</b>
                </TableCell>
                <TableCell>{singleLead?.mobileNo}</TableCell>
              </TableRow>
              {page == "Lead" && (
                <TableRow>
                  <TableCell>
                    <b>City</b>
                  </TableCell>
                  <TableCell>{singleLead?.city}</TableCell>
                </TableRow>
              )}
              {page == "Lead" && (
                <TableRow>
                  <TableCell>
                    <b>Monthly Income</b>
                  </TableCell>
                  <TableCell>{singleLead.monthlyIncome}</TableCell>
                </TableRow>
              )}
              {page == "User" && (
                <TableRow>
                  <TableCell>
                    <b>Employee Id</b>
                  </TableCell>
                  <TableCell>{singleLead?.employeeID}</TableCell>
                </TableRow>
              )}
              {page == "User" && (
                <TableRow>
                  <TableCell>
                    <b>Bank Name</b>
                  </TableCell>
                  <TableCell>{singleLead?.bankName}</TableCell>
                </TableRow>
              )}
              {page == "User" && (
                <TableRow>
                  <TableCell>
                    <b>Account Holder Number</b>
                  </TableCell>
                  <TableCell>{singleLead?.accountHolderName}</TableCell>
                </TableRow>
              )}
              {page == "User" && (
                <TableRow>
                  <TableCell>
                    <b>Account Number</b>
                  </TableCell>
                  <TableCell>{singleLead?.accountNumber}</TableCell>
                </TableRow>
              )}

              {page == "User" && (
                <TableRow>
                  <TableCell>
                    <b>I.F.S.C Code</b>
                  </TableCell>
                  <TableCell>{singleLead?.ifscCode}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      {page == "Lead" && urlUpdation?.length > 0 && (
        <div className="container mt-3">
          <Table border={Table}>
            <TableHead>
              <TableCell style={{ border: "1px solid black" }}>Title</TableCell>
              <TableCell style={{ border: "1px solid black" }}>Image</TableCell>
              <TableCell style={{ border: "1px solid black" }}>url</TableCell>
              <TableCell style={{ border: "1px solid black" }}>
                Remark
              </TableCell>
              <TableCell style={{ border: "1px solid black" }}>
                Status
              </TableCell>
            </TableHead>
            {urlUpdation?.map((row) => (
              <TableBody>
                <TableCell style={{ border: "1px solid black" }}>
                  {row?.urlId?.title}
                </TableCell>
                <TableCell style={{ border: "1px solid black" }}>
                  <img
                    src={getBaseUrl2() + row?.image}
                    width="100px"
                    height="100px"
                  />
                </TableCell>
                <TableCell style={{ border: "1px solid black" }}>
                  {row?.urlId?.url}
                </TableCell>
                <TableCell style={{ border: "1px solid black" }}>
                  {row?.imageStatus}
                </TableCell>
                {/* <TableCell style={{ border: "1px solid black" }}>{row?.accountStatus}</TableCell> */}
                <TableCell style={{ border: "1px solid black" }}>
                  <select
                    className={`${
                      row?.status === "Inactive"
                        ? s.inactive_admin
                        : row?.status === "Active"
                        ? s.active_admin
                        : s.progress_admin
                    } ${s["option"]}`}
                    style={{
                      border: "none",
                      fontSize: "14px",
                      outline: "none",
                      color: "black",
                      fontWeight: "600",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                    value={row?.status}
                    onChange={(e) => updateUserStatus(e, row)}
                    // aria-label="Default select example"
                  >
                    {/* <option defaultValue>{row?.leadId?.follow_up_status}</option> */}
                    <option value="Pending">Pending</option>
                    <option value="Active">Done</option>
                    <option value="Inactive">Reject</option>
                  </select>
                </TableCell>
              </TableBody>
            ))}
          </Table>
        </div>
      )}
      {page === "Lead" && urlUpdation?.length <= 0 && (
        <div style={{ width: "100%", marginBlock: "1rem" }}>
          <div
            style={{
              border: "1px solid black",
              width: "25rem",
              margin: "auto",
              padding: "1rem",
            }}
          >
            <h4>Links data are not found</h4>
          </div>
        </div>
      )}
      <div className="container mt-4">
        <div className={s["card"]}>
          <div className="card_item">
            <div
              className={s["card_image"]}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleImage(getBaseUrl2() + singleLead?.aadhaarFrontPic);
              }}
            >
              <img
                src={getBaseUrl2() + singleLead?.aadhaarFrontPic}
                width="300px"
                height="200px"
              ></img>
            </div>
            <div className="card_text">Aadhar Front Pic</div>
          </div>
          <div className="card_item">
            <div
              className={s["card_image"]}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleImage(getBaseUrl2() + singleLead?.aadhaarBackPic);
              }}
            >
              <img
                src={getBaseUrl2() + singleLead?.aadhaarBackPic}
                width="300px"
                height="200px"
              ></img>
            </div>
            <div className="card_text">Aadhar Back Pic</div>
          </div>
        </div>
        <div className={s["card"]}>
          <div className="card_item">
            <div
              className={s["card_image"]}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleImage(getBaseUrl2() + singleLead?.panFrontPic);
              }}
            >
              <img
                src={getBaseUrl2() + singleLead?.panFrontPic}
                width="300px"
                height="200px"
              ></img>
            </div>
            <div className="card_text">PAN Front Pic</div>
          </div>
          <div className="card_item">
            <div
              className={s["card_image"]}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleImage(getBaseUrl2() + singleLead?.passbook);
              }}
            >
              <img
                src={getBaseUrl2() + singleLead?.passbook}
                width="300px"
                height="200px"
              ></img>
            </div>
            <div className="card_text">Passbook Pic</div>
          </div>
        </div>
        {page === "Lead" && (
          <div className="demart">
            <div className={s["card"]}>
              <div className="card_item">
                <div
                  className={s["card_image"]}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleImage(
                      getBaseUrl2() + singleLead?.dematConsentFrontPic
                    );
                  }}
                >
                  <img
                    src={getBaseUrl2() + singleLead?.dematConsentFrontPic}
                    width="300px"
                    height="200px"
                  ></img>
                </div>
                <div className="card_text">Demat Consent Front Pic</div>
              </div>
              <div className="card_item">
                <div
                  className={s["card_image"]}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleImage(
                      getBaseUrl2() + singleLead?.dematConsentBackPic
                    );
                  }}
                >
                  <img
                    src={getBaseUrl2() + singleLead?.dematConsentBackPic}
                    width="300px"
                    height="200px"
                  ></img>
                </div>
                <div className="card_text">Demat Consent Back Pic</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ImagePopUp
        imagePopUp={imagePopUp}
        setimagePopUp={setimagePopUp}
        imageData={imageData}
      />
    </div>
  );
};

export default ViewPage;
