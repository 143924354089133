import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import s from "./faq.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import {
  create_admin_api,
  create_banner_api,
  getroll_admin_api,
  update_admin_api,
  update_banner_api,
} from "../api/admin";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addFaq_api, updateFaq_api } from "../api/faq";
const AddFaq = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.data?._id;
  const [isLoading, setisLoading] = useState(false);
  const [question, setquestion] = useState("");
  const [status, setstatus] = useState("");
  const [priority, setpriority] = useState("");
  const [file, setfile] = useState(null);
  const [answer, setanswer] = useState("");
  const pagetype = location.state.pagetype;
  useEffect(() => {
    setquestion(location?.state?.data?.question);
    setanswer(location?.state?.data?.answer);
  }, [location]);

  const createFaq = async () => {
    console.log(pagetype == "Add");

    if (pagetype == "Add") {
      let temp = {
        question: question,
        answer: answer,
      };

      try {
        let res = await addFaq_api(temp);
        if (res.data.status) {
          navigate("/faq");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      let temp = {
        question: question,
        answer: answer,
      };
      try {
        let res = await updateFaq_api(id, temp);
        if (res.data.status) {
          navigate("/faq");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Question</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="title"
                  value={question}
                  onChange={(e) => setquestion(e.target.value)}
                  placeholder="Question"
                  rows="2"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Answer</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="answer"
                  value={answer}
                  onChange={(e) => setanswer(e.target.value)}
                  placeholder="Answer"
                  rows="6"
                />
              </div>
            </Grid>
          </Grid>
          <div className={s["form-login-btn"]} onClick={() => createFaq()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddFaq;
