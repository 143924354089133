import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl, getBaseUrl2 } from "../../utils";

const getFaq = getBaseUrl() + "/admin/getFaq";
// const statusUpdate_banner = getBaseUrl2() + "";
const deleteFaq = getBaseUrl() + "/admin/deleteFaq";
const addFaq = getBaseUrl() + "/admin/addFaq";
const updateFaq = getBaseUrl() + "/admin/updateFaq";

export const updateFaq_api = async (id, data) => {
  let config = {
    method: "patch",
    url: updateFaq,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: {
      faqId: id,
      question: data.question,
      answer: data.answer,
    },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addFaq_api = async (data) => {
  let config = {
    method: "post",
    url: addFaq,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: { faq: data },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteFaq_api = async (data) => {
  let config = {
    method: "delete",
    url: deleteFaq,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: { faqId: data },
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFaq_api = async (data) => {
  let config = {
    method: "get",
    url: getFaq,
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

//  this api is used for the send notification
const send_notification = getBaseUrl() + "/admin/sendNotification";
export const sendNotification_api = async (data) => {
  let config = {
    method: "post",
    url: send_notification,
    headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token") || Cookies.get("token")
      }`,
    },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
