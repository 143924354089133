import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import s from "./notification.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";

import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { sendNotification_api } from "../api/faq";
import Loder from "../../Loder/Loder";
const SendNotification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setisLoading] = useState(false);
  const [title, settitle] = useState("");
  const [message, setmessage] = useState("");
  useEffect(() => {
    settitle(location?.state?.data?.title);
    setmessage(location?.state?.data?.message);
  }, [location]);

  const sendNotification = async () => {
    setisLoading(true);
    let temp = {
      title: title,
      message: message,
    };

    try {
      //  this response come from the add faq api page
      let res = await sendNotification_api(temp);
      if (res.data.status) {
        setisLoading(false);
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      setisLoading(false);
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            Send Notification
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">title</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="title"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                  placeholder="title"
                  rows="2"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">message</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="message"
                  value={message}
                  onChange={(e) => setmessage(e.target.value)}
                  placeholder="message"
                  rows="3"
                />
              </div>
            </Grid>
          </Grid>
          <div
            className={s["form-login-btn"]}
            onClick={() => sendNotification()}
          >
            <Custombutton>Send Notification</Custombutton>
          </div>
        </Card>
      </div>
      <Loder loading={isLoading} />
    </>
  );
};

export default SendNotification;
